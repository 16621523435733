body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	height: 100%;
}

html {
    height: 100vh;
    scroll-behavior: smooth;
}

a:link {
	text-decoration: none;
}
a:hover {
	color: black;
	font-family: arial;
	text-decoration: none;
}
a:visited {
	color: black;
	font-family: arial;
	text-decoration: none;
}
a:active {
    color: black;
}
a:link, a:visited, a:active {
    color: inherit;
}
* {
	outline: none !important;
}
*:focus {
	outline: none !important;
}
textarea:focus,
input:focus {
	outline: none !important;
}
a {
	text-decoration: none !important;
	outline: none !important;
	color: black;
}
