div.footer_container {
    background-color: #383838;
    padding: 3%;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}

div.texto_footer  {
    display: flex;
    width: 50%;
}

div.footer_container .texto_footer p {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 20px;
    margin-left: 10%;
    margin-top: 1%;
    color: #ffffff;
}


div.footer_container .imagenes_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%; 
    margin-right: 100px; 

}

div.footer_container .imagenes_footer img {
    max-width: 10%;
    height: 100%;
    margin-right: 2%;
}